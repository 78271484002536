import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { BannerMobileApp } from "../../../components/Blog/postElements/BannerMobile";
import { BlogVideo } from "../../../components/Blog/postElements/BlogVideo";
import { BlogTable } from "../../../components/Blog/postElements/BlogTable";
import { ImageMaxWidth } from "../../../components/Blog/postElements/ImageMaxWidth";
import { PostIntro } from "../../../components/Blog/postElements/PostIntro";
import { FullWidthImage } from "../../../components/Blog/postElements/FullWidthImage";
export const _frontmatter = {
  "title": "The Five Best Mobile App Payment Solutions For 2021, part 3 - Square vs PayU",
  "categories": ["mobile payments", "fintech", "flutter", "business", "technical"],
  "authors": ["Tomasz Michlewski"],
  "abstract": "This third article looks at two mobile application payment solutions, both highly-regarded and more regionally-specific than our previous recommendations. The solutions, from Square and PayU, are reviewed in detail and compared. Read the article to learn more.",
  "imageSource": "./main.png",
  "link": "mobile-payments-solutions/square-vs-payU",
  "date": "2021-06-29T00:00:00.000Z",
  "seoTitle": "Top mobile payment solutions flutter app Square vs PayU",
  "seoDescription": "Square vs PayU, What is the best payment solution for a mobile application that targets the local markets. Read an overview of the best payment processors for a mobile app.",
  "seoKeyPhrase": "top mobile payment solutions Square vs PayU",
  "seoKeyWords": "Google Pay, Apple Pay, payment processor, payment gateway, Braintree, PayPal, Stripe, Mollie, PayU, Square, mobile app payments"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PostIntro title="Introduction" description={<p>Hi, I’m Tomasz Michlewski from AllBright.io. Welcome to the third part of “The Five Best Mobile App Payment Solutions For 2021”.</p>} imageUrl={"/img/payment-solutions-part-3.png"} mdxType="PostIntro" />
    <p>{`In part 1, I described what a payment processor is and included a short description of the best payment gateway providers, according to our experience. That instalment ended with a brief comparison of all five solutions.`}</p>
    <p>{`Part 2 then laid out the details of these three solutions: `}<a parentName="p" {...{
        "href": "https://www.braintreepayments.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Braintree`}</a>{`, `}<a parentName="p" {...{
        "href": "https://stripe.com/en-pl",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Stripe`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.mollie.com/en",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Mollie`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In this third part, I will focus on the other solutions Allbright recommends, namely `}<a parentName="p" {...{
          "href": "https://squareup.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Square`}</a>{` and `}<a parentName="p" {...{
          "href": "https://poland.payu.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`PayU`}</a>{`.`}</p>
    </blockquote>
    <p>{`Keeping to the structure, as seen in part 2, this article begins by characterising both suppliers across three categories:`}</p>
    <ul>
      <li parentName="ul">{`Geographic availability`}</li>
      <li parentName="ul">{`Costs and payment methods`}</li>
      <li parentName="ul">{`Technological constraints`}</li>
    </ul>
    <p>{`Finally, we present the pros and cons for each solution.`}</p>
    <p>{`So, let’s move on to the first payment processor, `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{`.`}</p>
    <h2 {...{
      "id": "Square"
    }}>{`Square`}</h2>
    <ImageMaxWidth imageSrc="/img/fivePaymentSolutions/Square-logo.png" alt="Square icon" maxWidth={170} customStyles={{
      margin: '50px 0'
    }} mdxType="ImageMaxWidth" />
    <p><a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` is a US company that provides the highest quality payment gateways for mobile applications to businesses of all kinds. `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` continually evolves according to the market’s needs. A case in point: `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` responded rapidly to COVID-19 restrictions by offering vendors new solutions. These included On-Demand Delivery, made available alongside `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` Online, and combined with flexible payment terms for the duration of the pandemic countermeasures. They also developed a user-friendly application which helped small businesses get financial support during the crisis. You can read more about their vendors’ stories `}<a parentName="p" {...{
        "href": "https://squareup.com/us/en/stories",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "Geographic-availability"
    }}>{`Geographic availability`}</h3>
    <p>{`As mentioned before, the company focuses on providing its solution within the US, but are looking to expand to other regions. The currently supported countries are: US, Canada, Japan, Australia, the United Kingdom, Republic of Ireland, France and Spain. Transactions outside these countries are not supported.`}</p>
    <p><a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` does not support cross-border card payments. Card transactions attempted while outside the country the account was activated do not work, but you can receive funds while travelling abroad.`}</p>
    <p>{`A complication to consider is, if your software product is used in more than one country, you will need:`}</p>
    <ul>
      <li parentName="ul">{`A Square account for each country`}</li>
      <li parentName="ul">{`A unique email address for each account`}</li>
      <li parentName="ul">{`Separate identity verification for each account`}</li>
    </ul>
    <h3 {...{
      "id": "Payments-and-usage-fees"
    }}>{`Payments and usage fees`}</h3>
    <p>{`Fees for all single transactions passing through `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square’s`}</a>{` SDK are set at a fixed rate of 2.9% + 30¢`}</p>
    <FullWidthImage imageSrc="/img/fivePaymentSolutions/payment-fees.png" alt="Square icon with payments" customStyle={{
      marginTop: '0'
    }} mdxType="FullWidthImage" />
    <p>{`When it comes to payment methods, there are limited options: credit/debit cards, `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` gift cards, Apple Pay and Google Pay.`}</p>
    <h3 {...{
      "id": "Integration-capabilities"
    }}>{`Integration capabilities`}</h3>
    <p>{`Now let’s move on to the best part of `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square’s`}</a>{` system and the main reason why it’s on our list. `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` supplies a ready to use UI and it looks like this:`}</p>
    <BlogVideo video="https://videos.ctfassets.net/2d5q1td6cyxq/6lPGaoPiz6Cei6wC6IqQUc/50daa458461db0ff14edc7ed5376f481/PD00713_-_GB_iphone_card_number_video_.mp4" mdxType="BlogVideo" />
    <p><a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` supports implementations for:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.squareup.com/docs/in-app-payments-sdk/build-on-android",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Android`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.squareup.com/docs/in-app-payments-sdk/build-on-ios",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`iOS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.squareup.com/docs/in-app-payments-sdk/flutter",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Flutter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.squareup.com/docs/in-app-payments-sdk/react-native",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`React Native`}</a></li>
    </ul>
    <p>{`And `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` takes good care of the humble developer too:`}</p>
    <ul>
      <li parentName="ul">{`Full support for both native and cross-platform technologies`}</li>
      <li parentName="ul">{`Both customisable and ready to implement UIs are supported`}</li>
      <li parentName="ul">{`Comprehensive documentation and implementation guides are available`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Of all the payment processors we recommend, `}<a parentName="p" {...{
          "href": "https://squareup.com",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Square`}</a>{` is top in terms of integration.`}</p>
    </blockquote>
    <p>{`Official support for Flutter and React Native means that you do not have to decide on a native SDK full of legacy; you can use a more modern solution. As a developer, this is all you could ask for! They understand our needs and we find it easy to recommend them.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Pros`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Excellent coverage of the United States`}</li>
      <li parentName="ul">{`SDK provides wide range of in-app technical solutions`}</li>
      <li parentName="ul">{`Simple fee structure`}</li>
    </ul>
    <p><strong parentName="p"><em parentName="strong">{`Cons`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Cross-border payments not supported`}</li>
      <li parentName="ul">{`Complicated system for multiple locations`}</li>
      <li parentName="ul">{`Limited range of payment methods`}</li>
    </ul>
    <BannerMobileApp description="Want to integrate Square payments into your app?" mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "PayU"
    }}>{`PayU`}</h2>
    <p>{`Whereas `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` has a strong base in US markets, let us now turn to a company that is explicitly geared towards the East Asia market. `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{` is a Polish company whose priority is to provide the best possible support for local payments. They hold licenses from national banks and local regulators and, thanks to this, their clients can offer a wide range of local payment methods.`}</p>
    <ImageMaxWidth imageSrc="/img/fivePaymentSolutions/PayU_END_map.jpg" alt="PayU map" maxWidth={1000} mdxType="ImageMaxWidth" />
    <h3 {...{
      "id": "Geographic-availability-1"
    }}>{`Geographic availability`}</h3>
    <p><a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{` is available in the following countries: South Africa, Nigeria, Kenya, Brazil, Argentina, Chile, Colombia, Mexico, Panama, Peru, Czech Republic, Poland, Romania, Russia, Turkey, India. However, if you are prioritising Western Europe, don’t give up yet, since `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{` can also support card processing in those countries.`}</p>
    <h3 {...{
      "id": "Payments-and-usage-fees-1"
    }}>{`Payments and usage fees`}</h3>
    <p>{`In the case of `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{`, the issue of price is a bit more complicated because it varies by the region. We can give two countries as examples: Poland, `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU’s`}</a>{` home country; and India, where PayU is the undisputed market leader.`}</p>
    <p>{`As of today, late 2022, `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{` offers a discount for the Polish market, which is going to last to December 15.`}</p>
    <p>{`Thus, the price for a single transaction using `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{`, Blik, or online transfer is a flat 1.2% (standard fee: 2.3%). For payment cards, Google pay and Apple pay, the price for a single transaction is 1.2% + 0.3 PLN (standard price 2.3%).`}</p>
    <p>{`Fortunately, in the case of India, there is only a charge per transaction.`}</p>
    <p>{`PayU charges flat 2% + GST (18%) for each transaction. Example:`}</p>
    <p><strong parentName="p">{`Customer Paid: ₹`}</strong>{` 100`}</p>
    <p><strong parentName="p">{`Transaction charge: ₹`}</strong>{` 2`}</p>
    <p><strong parentName="p">{`GST: ₹`}</strong>{` 0.36`}</p>
    <p><strong parentName="p">{`Amount settled:`}</strong>{` 100 - 2 - 0.36 = ₹ 97.64`}</p>
    <p>{`The situation is different, however, with American Express and Diners Cards. The service fee is 3% + India’s GST (Goods & Services Tax) for international transactions & EMI (Equated Monthly Instalments) payment options. There is also a set-up fee along with Annual Maintenance Charges (AMC). The service fee is 3% + ₹6 for every transaction.`}</p>
    <p>{`What is GST? It is an indirect tax that has replaced other taxes and fees applied by central and state administrations.`}</p>
    <p><a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{` `}<strong parentName="p">{`focuses on providing the best support at local level for each supported country, so their payment methods include many local banks and transfer methods.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "http://developers.payu.com/en/overview.html",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Here`}</a>{` is a very extensive list of the supported methods. Default payment methods for smartphones are available regardless of the region, meaning that Google Pay and Apple Pay are accessible everywhere.`}</p>
    <h3 {...{
      "id": "Integration-capabilities-1"
    }}>{`Integration capabilities`}</h3>
    <ImageMaxWidth imageSrc="/img/fivePaymentSolutions/mobile_addcard_screen.png" alt="Integration capabilities" maxWidth={300} mdxType="ImageMaxWidth" />
    <p><a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{` supports implementations for:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.squareup.com/docs/in-app-payments-sdk/build-on-android",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`Android`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.squareup.com/docs/in-app-payments-sdk/build-on-ios",
          "target": "_blank",
          "rel": "nofollow"
        }}>{`iOS`}</a></li>
    </ul>
    <p>{`In this case, there’s no ready-to-use UI available to drop into an application. However, `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{` does provide an `}<a parentName="p" {...{
        "href": "https://developer.payumoney.com/android/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Android`}</a>{` and an `}<a parentName="p" {...{
        "href": "https://developer.payumoney.com/ios/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`iOS`}</a>{` SDK, which handles all the payment problems and assures safe use. Implementation is made easier as PayU includes access to extensive guides and documentation, and also a series of tutorials on `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=gnQ0IF3XniM",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`YouTube`}</a>{`, which show how to implement their SDK in the example applications from beginning to end. Not bad! However, this time cross-platform technologies are entirely unsupported, so when choosing `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{`, we stick with the native SDK.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Pros`}</em></strong></p>
    <ul>
      <li parentName="ul">{`Comprehensive resources for developers`}</li>
      <li parentName="ul">{`Good support for local markets`}</li>
    </ul>
    <p><strong parentName="p"><em parentName="strong">{`Cons`}</em></strong></p>
    <ul>
      <li parentName="ul">{`No support for cross-platform technology`}</li>
      <li parentName="ul">{`Complicated fee structure`}</li>
    </ul>
    <BannerMobileApp description="Want to integrate PayU payments into your app?" mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "Summary"
    }}>{`Summary`}</h2>
    <p><a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` and `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{` are payment gateway providers focused on local markets. They do not target international markets but focus on delivering the highest quality in specific parts of the world. Thanks to their niche focus, they can apply a more individual approach.`}</p>
    <p>{`In summary, `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` is a company that focuses on new solutions, officially supporting technologies such as Flutter and React Native. The company strives to provide its customers with the most transparent cost structure possible. Unfortunately, the number of available payment methods is minimal, but this is because they restrict themselves to solutions that are well-known to their customers. Square specialises in solutions for the English-speaking market and is unrivalled in its areas of operation.`}</p>
    <p><a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{`, unlike `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{`, is not so progressive. They realise that a large number of users who use their services are not so well versed in technology. Instead of targeting the latest solutions, they focus their efforts on providing their customers with something familiar. Their offer of local banks is unrivalled, which makes their clients very fond of `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{`. Developers, however, cannot count on as much facilitation as with Square.`}</p>
    <p>{`Both have their pros and cons, and it’s hard to say which approach is better. `}<a parentName="p" {...{
        "href": "https://squareup.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Square`}</a>{` and `}<a parentName="p" {...{
        "href": "https://poland.payu.com",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`PayU`}</a>{`, however, both offer services at the highest level. Which to choose depends on individual business requirements and the project.`}</p>
    <BlogTable type="mixed" customClass="centered-text white-space-normal" mdxType="BlogTable">
  | Geographic availability | PayU | Square |
  | :-----------------: | :-------: | :----------------: |
  | USA | — | ● |
  | CANADA | — | ● |
  | AUSTRALIA | — | ● |
  | JAPAN | — | ● |
  | NORTH AMERICA | (Mexico) | — |
  | SOUTH AMERICA | — | — |
  | EASTERN EUROPE | ● | (— )|
  | CENTRAL EUROPE | ● | — |
  | WESTERN EUROPE | — | ● |
  | ASIA | ● | — |
    </BlogTable>
    <BlogTable type="mixed" customClass="centered-text white-space-normal" mdxType="BlogTable">
  | Default payment methods for mobile applications | PayU | Square |
  | :-----------------: | :-------: | :----------------: |
  | GOOGLE PAY | ● | ● |
  | APPLE PAY | ● | ● |
    </BlogTable>
    <BlogTable type="mixed" customClass="centered-text white-space-normal" mdxType="BlogTable">
  | Fees | PayU | Square |
  | :-----------------: | :-------: | :----------------: |
  | EU CARDS | 1.2% - 2.3% + 0.3PLN | 2.9% + 30¢ |
  | NON EU CARDS | — | 2.9% + €0.25 |
  | AMERICA EXPRESS | N/A | N/A |
  | REGISTRATION | yes | — |
    </BlogTable>
    <BlogTable type="mixed" customClass="centered-text white-space-normal" mdxType="BlogTable">
  | Technology | Braintree | Stripe | Square |
  | :-----------------: | :-------: | :----------------: |
  | ANDROID | ● | ●●● |
  | IOS | ● | ●●● |
  | FLUTTER | — | ●●● |
    </BlogTable>
    <p>{`And that’s it! I hope by now you have a better idea of which solution is best for you, or at least you are slightly better informed as to what is out there. If you need specialists in the field of building mobile applications, or the implementation of payment processors in a mobile app, please contact us on `}<a parentName="p" {...{
        "href": "mailto:contact@allbright.io",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`contact@allbright.io`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      